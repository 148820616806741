@values max-small from "@/styles/variables.module.css";

.text {
  margin: 16px 0;
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: Oxygen;

  @media max-small {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.smallHeader {
  margin: 21px 0;
  color: #000;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  font-family: Oxygen;
  text-transform: uppercase;

  @media max-small {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  @media max-small {
    margin-top: 20px;
  }

  @media max-small {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.cta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;

  @media max-small {
    width: 100%;
  }
}
